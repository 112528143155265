import { useState, useEffect } from 'react';
import { 
  TrashIcon, 
  ClipboardDocumentIcon, 
  PlusIcon, 
  XMarkIcon,
  PencilSquareIcon 
} from '@heroicons/react/24/outline';

const CONTENT_TEMPLATES = {
  'application/json': {
    label: 'JSON Object',
    content: `{
  "message": "Hello World",
  "status": "success",
  "data": {
    "id": 1,
    "name": "Example"
  }
}`
  },
  'text/plain': {
    label: 'Plain Text',
    content: 'Hello World! This is a plain text response.'
  },
  'text/html': {
    label: 'HTML Page',
    content: `<!DOCTYPE html>
<html>
<head>
    <title>Example Page</title>
</head>
<body>
    <h1>Hello World</h1>
    <p>This is a sample HTML page.</p>
</body>
</html>`
  },
  'application/xml': {
    label: 'XML Document',
    content: `<?xml version="1.0" encoding="UTF-8"?>
<root>
    <message>Hello World</message>
    <data>
        <item id="1">
            <name>Example</name>
        </item>
    </data>
</root>`
  }
};

function Mapping() {
  const [mappings, setMappings] = useState({});
  const [files, setFiles] = useState([]);
  const [newEndpoint, setNewEndpoint] = useState('');
  const [selectedFile, setSelectedFile] = useState('');
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState('');
  const [isCustomResponse, setIsCustomResponse] = useState(false);
  const [customContent, setCustomContent] = useState('');
  const [customHeaders, setCustomHeaders] = useState([{ key: '', value: '' }]);
  const [customContentType, setCustomContentType] = useState('application/json');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingEndpoint, setEditingEndpoint] = useState(null);
  const [editContent, setEditContent] = useState('');
  const [editHeaders, setEditHeaders] = useState([]);
  const [editContentType, setEditContentType] = useState('');

  useEffect(() => {
    fetchMappings();
    fetchFiles();
  }, []);

  const fetchMappings = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mappings/mappings`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setMappings(data);
    } catch (error) {
      console.error('Error fetching mappings:', error);
      setError('Failed to fetch mappings');
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files/files`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setFiles(data);
    } catch (error) {
      console.error('Error fetching files:', error);
      setError('Failed to fetch files');
    }
  };

  const handleAddHeader = () => {
    setCustomHeaders([...customHeaders, { key: '', value: '' }]);
  };

  const handleRemoveHeader = (index) => {
    setCustomHeaders(customHeaders.filter((_, i) => i !== index));
  };

  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...customHeaders];
    newHeaders[index][field] = value;
    setCustomHeaders(newHeaders);
  };

  const handleAddMapping = async (e) => {
    e.preventDefault();
    if (!newEndpoint) {
      setError('Please provide an endpoint');
      return;
    }

    if (!isCustomResponse && !selectedFile) {
      setError('Please select a file');
      return;
    }

    try {
      const mappingData = isCustomResponse ? {
        endpoint: newEndpoint,
        isCustom: true,
        customContent,
        customHeaders: customHeaders.filter(h => h.key && h.value),
        contentType: customContentType
      } : {
        endpoint: newEndpoint,
        filename: selectedFile
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mappings/mappings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(mappingData),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      await fetchMappings();
      setNewEndpoint('');
      setSelectedFile('');
      setCustomContent('');
      setCustomHeaders([{ key: '', value: '' }]);
      setIsCustomResponse(false);
      setError(null);
    } catch (error) {
      console.error('Error adding mapping:', error);
      setError('Failed to add mapping');
    }
  };

  const handleDeleteMapping = async (endpoint) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mappings/mappings/${endpoint}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      await fetchMappings();
      setError(null);
    } catch (error) {
      console.error('Error deleting mapping:', error);
      setError('Failed to delete mapping');
    }
  };

  const getMappedUrl = (endpoint) => {
    const baseUrl = process.env.REACT_APP_API_URL.replace('/api', '');
    return `${baseUrl}/serve/${endpoint}`;
  };

  const copyToClipboard = async (endpoint) => {
    const url = getMappedUrl(endpoint);
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        // Modern browsers - use Clipboard API
        await navigator.clipboard.writeText(url);
      } else {
        // Fallback for older browsers
        const textarea = document.createElement('textarea');
        textarea.value = url;
        textarea.style.position = 'fixed';  // Avoid scrolling to bottom
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        
        try {
          document.execCommand('copy');
          document.body.removeChild(textarea);
        } catch (err) {
          console.error('Fallback copy failed:', err);
          document.body.removeChild(textarea);
          throw new Error('Copy failed');
        }
      }
      
      setCopySuccess(endpoint);
      setTimeout(() => setCopySuccess(''), 2000); // Clear success message after 2 seconds
    } catch (err) {
      console.error('Failed to copy URL:', err);
      setError('Failed to copy URL to clipboard');
      setTimeout(() => setError(null), 3000); // Clear error after 3 seconds
    }
  };

  const getDisplayValue = (mapping) => {
    if (typeof mapping === 'string') return mapping;
    if (mapping.type === 'custom') {
      return `Custom Response (${mapping.contentType})`;
    }
    return mapping.filename || 'Unknown';
  };

  const handleEditMapping = (endpoint, mapping) => {
    if (mapping.type === 'custom') {
      setEditingEndpoint(endpoint);
      setEditContent(mapping.content);
      setEditHeaders(mapping.headers || [{ key: '', value: '' }]);
      setEditContentType(mapping.contentType);
      setIsEditModalOpen(true);
    }
  };

  const handleSaveEdit = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/mappings/mappings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          endpoint: editingEndpoint,
          isCustom: true,
          customContent: editContent,
          customHeaders: editHeaders.filter(h => h.key && h.value),
          contentType: editContentType
        }),
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      await fetchMappings();
      setIsEditModalOpen(false);
      setEditingEndpoint(null);
      setError(null);
    } catch (error) {
      console.error('Error updating mapping:', error);
      setError('Failed to update mapping');
    }
  };

  const handleAddEditHeader = () => {
    setEditHeaders([...editHeaders, { key: '', value: '' }]);
  };

  const handleRemoveEditHeader = (index) => {
    setEditHeaders(editHeaders.filter((_, i) => i !== index));
  };

  const handleEditHeaderChange = (index, field, value) => {
    const newHeaders = [...editHeaders];
    newHeaders[index][field] = value;
    setEditHeaders(newHeaders);
  };

  const handleApplyTemplate = (contentType, isEdit = false) => {
    const template = CONTENT_TEMPLATES[contentType];
    if (template) {
      if (isEdit) {
        setEditContent(template.content);
        setEditContentType(contentType);
      } else {
        setCustomContent(template.content);
        setCustomContentType(contentType);
      }
    }
  };

  const getFileOptions = (files) => {
    return files.map(file => ({
      value: file.path,
      label: file.name
    }));
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-4">Resource Mappings</h2>
      
      {error && (
        <div className="mb-4 bg-red-50 text-red-500 p-4 rounded">
          {error}
        </div>
      )}

      <form onSubmit={handleAddMapping} className="mb-6 bg-gray-50 p-4 rounded-lg">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Endpoint
            </label>
            <input
              type="text"
              value={newEndpoint}
              onChange={(e) => setNewEndpoint(e.target.value)}
              placeholder="e.g., prompt1"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Type
            </label>
            <select
              value={isCustomResponse ? 'custom' : 'file'}
              onChange={(e) => setIsCustomResponse(e.target.value === 'custom')}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="file">File</option>
              <option value="custom">Custom Response</option>
            </select>
          </div>
        </div>

        {!isCustomResponse ? (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              File
            </label>
            <select
              value={selectedFile}
              onChange={(e) => setSelectedFile(e.target.value)}
              className="w-full px-3 py-2 border rounded"
            >
              <option value="">Select a file</option>
              {getFileOptions(files).map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Content Type
              </label>
              <div className="space-y-2">
                <select
                  value={customContentType}
                  onChange={(e) => setCustomContentType(e.target.value)}
                  className="w-full px-3 py-2 border rounded"
                >
                  <option value="application/json">JSON</option>
                  <option value="text/plain">Plain Text</option>
                  <option value="text/html">HTML</option>
                  <option value="application/xml">XML</option>
                </select>
                <div className="flex flex-wrap gap-2">
                  {Object.entries(CONTENT_TEMPLATES).map(([type, template]) => (
                    <button
                      key={type}
                      type="button"
                      onClick={() => handleApplyTemplate(type)}
                      className="px-3 py-1 text-sm bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                    >
                      Use {template.label} Template
                    </button>
                  ))}
                </div>
              </div>
            </div>
            
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Custom Headers
              </label>
              <div className="space-y-2">
                {customHeaders.map((header, index) => (
                  <div key={index} className="flex gap-2">
                    <input
                      type="text"
                      placeholder="Header Key"
                      value={header.key}
                      onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
                      className="flex-1 px-3 py-2 border rounded"
                    />
                    <input
                      type="text"
                      placeholder="Header Value"
                      value={header.value}
                      onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
                      className="flex-1 px-3 py-2 border rounded"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveHeader(index)}
                      className="p-2 text-red-600 hover:text-red-900"
                    >
                      <XMarkIcon className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddHeader}
                  className="flex items-center gap-1 text-blue-600 hover:text-blue-800"
                >
                  <PlusIcon className="h-4 w-4" />
                  Add Header
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Response Content
              </label>
              <textarea
                value={customContent}
                onChange={(e) => setCustomContent(e.target.value)}
                placeholder="Enter response content..."
                rows={5}
                className="w-full px-3 py-2 border rounded font-mono"
              />
            </div>
          </div>
        )}

        <div className="mt-4">
          <button
            type="submit"
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Add Mapping
          </button>
        </div>
      </form>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Endpoint
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Type
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Details
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                URL
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {Object.entries(mappings).map(([endpoint, mapping]) => (
              <tr key={endpoint}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <code className="text-sm">/{endpoint}</code>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                    ${mapping.type === 'custom' ? 'bg-purple-100 text-purple-800' : 'bg-blue-100 text-blue-800'}`}>
                    {mapping.type === 'custom' ? 'Custom' : 'File'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {mapping.type === 'custom' ? (
                    <div className="text-sm text-gray-900">
                      <div>Content Type: {mapping.contentType}</div>
                      {mapping.headers && mapping.headers.length > 0 && (
                        <div className="text-xs text-gray-500">
                          {mapping.headers.length} custom header(s)
                        </div>
                      )}
                    </div>
                  ) : (
                    <span className="text-sm text-gray-900">{mapping.filename}</span>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    <code className="text-sm text-gray-600">{getMappedUrl(endpoint)}</code>
                    <button
                      onClick={() => copyToClipboard(endpoint)}
                      className="p-1 hover:bg-gray-100 rounded transition-colors"
                      title="Copy URL"
                    >
                      <ClipboardDocumentIcon className="h-5 w-5 text-gray-500" />
                    </button>
                    {copySuccess === endpoint && (
                      <span className="text-green-600 text-sm">Copied!</span>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center gap-2">
                    {mapping.type === 'custom' && (
                      <button
                        onClick={() => handleEditMapping(endpoint, mapping)}
                        className="text-blue-600 hover:text-blue-900"
                        title="Edit mapping"
                      >
                        <PencilSquareIcon className="h-5 w-5" />
                      </button>
                    )}
                    <button
                      onClick={() => handleDeleteMapping(endpoint)}
                      className="text-red-600 hover:text-red-900"
                      title="Delete mapping"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isEditModalOpen && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">
                  Edit Custom Response for /{editingEndpoint}
                </h3>
                <button
                  onClick={() => setIsEditModalOpen(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Content Type
                  </label>
                  <div className="space-y-2">
                    <select
                      value={editContentType}
                      onChange={(e) => setEditContentType(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                    >
                      <option value="application/json">JSON</option>
                      <option value="text/plain">Plain Text</option>
                      <option value="text/html">HTML</option>
                      <option value="application/xml">XML</option>
                    </select>
                    <div className="flex flex-wrap gap-2">
                      {Object.entries(CONTENT_TEMPLATES).map(([type, template]) => (
                        <button
                          key={type}
                          type="button"
                          onClick={() => handleApplyTemplate(type, true)}
                          className="px-3 py-1 text-sm bg-gray-100 text-gray-700 rounded hover:bg-gray-200"
                        >
                          Use {template.label} Template
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Custom Headers
                  </label>
                  <div className="space-y-2">
                    {editHeaders.map((header, index) => (
                      <div key={index} className="flex gap-2">
                        <input
                          type="text"
                          placeholder="Header Key"
                          value={header.key}
                          onChange={(e) => handleEditHeaderChange(index, 'key', e.target.value)}
                          className="flex-1 px-3 py-2 border rounded"
                        />
                        <input
                          type="text"
                          placeholder="Header Value"
                          value={header.value}
                          onChange={(e) => handleEditHeaderChange(index, 'value', e.target.value)}
                          className="flex-1 px-3 py-2 border rounded"
                        />
                        <button
                          type="button"
                          onClick={() => handleRemoveEditHeader(index)}
                          className="p-2 text-red-600 hover:text-red-900"
                        >
                          <XMarkIcon className="h-5 w-5" />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={handleAddEditHeader}
                      className="flex items-center gap-1 text-blue-600 hover:text-blue-800"
                    >
                      <PlusIcon className="h-4 w-4" />
                      Add Header
                    </button>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Response Content
                  </label>
                  <textarea
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    placeholder="Enter response content..."
                    rows={5}
                    className="w-full px-3 py-2 border rounded font-mono"
                  />
                </div>
              </div>

              <div className="mt-6 flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setIsEditModalOpen(false)}
                  className="px-4 py-2 border rounded text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleSaveEdit}
                  className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Mapping; 