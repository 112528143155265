import React, { useState, useEffect } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

const MetadataEditor = ({ file, apiUrl }) => {
  const [metadata, setMetadata] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (file) {
      fetchMetadata();
    }
  }, [file]);

  const fetchMetadata = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetch(`${apiUrl}/api/files/metadata/${file.path}`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setMetadata(data);
    } catch (error) {
      console.error('Error fetching metadata:', error);
      setError('Failed to fetch metadata');
    } finally {
      setLoading(false);
    }
  };

  const handleMetadataChange = (key, value, category = null) => {
    setMetadata(prev => {
      if (category) {
        return {
          ...prev,
          [category]: {
            ...prev[category],
            [key]: value
          }
        };
      }
      return {
        ...prev,
        [key]: value
      };
    });
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      const response = await fetch(`${apiUrl}/api/files/metadata/${file.path}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(metadata),
      });
      
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      
      const result = await response.json();
      if (result.error) throw new Error(result.error);
      
    } catch (error) {
      console.error('Error saving metadata:', error);
      setError('Failed to save metadata');
    } finally {
      setSaving(false);
    }
  };

  if (!file) {
    return (
      <div className="h-[70vh] flex items-center justify-center text-gray-500">
        Select a file to view metadata
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-[70vh] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[70vh] flex items-center justify-center text-red-500">
        <div className="flex items-center gap-2">
          <XCircleIcon className="w-5 h-5" />
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium">File Metadata</h3>
        <button
          onClick={handleSave}
          disabled={saving}
          className={`px-4 py-2 rounded ${
            saving 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-blue-600 hover:bg-blue-700 text-white'
          }`}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </button>
      </div>

      <div className="space-y-4">
        {metadata && Object.entries(metadata).map(([key, value]) => (
          <div key={key} className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              {key}
            </label>
            {typeof value === 'object' ? (
              <div className="pl-4 space-y-2">
                {Object.entries(value).map(([subKey, subValue]) => (
                  <div key={subKey}>
                    <label className="block text-sm text-gray-600">
                      {subKey}
                    </label>
                    <input
                      type="text"
                      value={subValue}
                      onChange={(e) => handleMetadataChange(subKey, e.target.value, key)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <input
                type="text"
                value={value}
                onChange={(e) => handleMetadataChange(key, e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default MetadataEditor; 