import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  FolderIcon,
  DocumentIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
  DocumentDuplicateIcon,
  ArrowDownTrayIcon,
} from '@heroicons/react/24/outline';

const FileExplorer = ({
  data,
  onFileSelect,
  onCreateFolder,
  onDeleteFolder,
  onDeleteFile,
  onMoveFile,
  selectedFile,
}) => {
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [draggedItem, setDraggedItem] = useState(null);
  const [showNewFolderInput, setShowNewFolderInput] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  const [dragOverFolder, setDragOverFolder] = useState(null);

  const toggleFolder = (path) => {
    const newExpanded = new Set(expandedFolders);
    if (newExpanded.has(path)) {
      newExpanded.delete(path);
    } else {
      newExpanded.add(path);
    }
    setExpandedFolders(newExpanded);
  };

  const handleCreateFolder = (parentPath) => {
    if (!newFolderName.trim()) return;
    
    const fullPath = parentPath 
      ? `${parentPath}/${newFolderName}` 
      : newFolderName;
    
    onCreateFolder(fullPath);
    setNewFolderName('');
    setShowNewFolderInput(null);
  };

  const handleDragStart = (e, path) => {
    setDraggedItem(path);
    e.dataTransfer.setData('text/plain', path);
    // Add a dragging class to the element
    e.currentTarget.classList.add('opacity-50');
  };

  const handleDragEnd = (e) => {
    setDraggedItem(null);
    setDragOverFolder(null);
    e.currentTarget.classList.remove('opacity-50');
  };

  const handleDragOver = (e, path) => {
    e.preventDefault();
    if (draggedItem && draggedItem !== path) {
      setDragOverFolder(path);
    }
  };

  const handleDragLeave = () => {
    setDragOverFolder(null);
  };

  const handleDrop = (e, targetPath) => {
    e.preventDefault();
    setDragOverFolder(null);
    
    if (!draggedItem || draggedItem === targetPath) return;
    
    const sourcePath = draggedItem;
    const fileName = sourcePath.split('/').pop();
    // If target is root, don't add extra slash
    const destPath = targetPath === '' ? fileName : `${targetPath}/${fileName}`;
    
    console.log('Moving file:', {
      sourcePath,
      destPath,
      targetPath,
      fileName
    });
    
    onMoveFile(sourcePath, destPath);
    setDraggedItem(null);
  };

  const handleDownload = async (file) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files/files/${encodeURIComponent(file.path)}`);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  const renderTree = (nodes, parentPath = '') => {
    return nodes.map((node) => {
      const fullPath = node.path;
      const isExpanded = expandedFolders.has(fullPath);
      const isDraggedOver = dragOverFolder === fullPath;
      
      return (
        <div key={fullPath} className="select-none">
          <div
            className={`group flex items-center gap-1 p-1 rounded transition-colors
              ${selectedFile === fullPath ? 'bg-blue-50' : 'hover:bg-gray-100'}
              ${isDraggedOver ? 'bg-blue-50 border border-blue-300' : ''}
              ${draggedItem === fullPath ? 'opacity-50' : ''}`}
            draggable={node.type === 'file'}
            onDragStart={(e) => handleDragStart(e, fullPath)}
            onDragEnd={handleDragEnd}
            onDragOver={(e) => node.type === 'folder' && handleDragOver(e, fullPath)}
            onDragLeave={() => node.type === 'folder' && handleDragLeave()}
            onDrop={(e) => node.type === 'folder' && handleDrop(e, fullPath)}
          >
            <div className="flex items-center gap-1 flex-grow min-w-0">
              {node.type === 'folder' && (
                <button 
                  onClick={() => toggleFolder(fullPath)}
                  className="p-1 hover:bg-gray-200 rounded"
                >
                  {isExpanded ? (
                    <ChevronDownIcon className="w-4 h-4" />
                  ) : (
                    <ChevronRightIcon className="w-4 h-4" />
                  )}
                </button>
              )}
              
              {node.type === 'folder' ? (
                <FolderIcon className="w-5 h-5 text-yellow-500 flex-shrink-0" />
              ) : (
                <DocumentIcon className="w-5 h-5 text-gray-500 flex-shrink-0" />
              )}
              
              <span 
                className="truncate"
                onClick={() => node.type === 'file' && onFileSelect(node)}
              >
                {node.name}
              </span>
            </div>
            
            <div className="flex items-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
              {node.type === 'folder' && (
                <button
                  onClick={() => setShowNewFolderInput(fullPath)}
                  className="p-1 hover:bg-gray-200 rounded"
                  title="Create folder"
                >
                  <PlusIcon className="w-4 h-4 text-gray-500" />
                </button>
              )}
              {node.type === 'file' && (
                <>
                  <button
                    onClick={() => handleDownload(node)}
                    className="p-1 hover:bg-gray-200 rounded"
                    title="Download file"
                  >
                    <ArrowDownTrayIcon className="w-4 h-4 text-gray-500" />
                  </button>
                  <button
                    onClick={() => onDuplicateFile(fullPath)}
                    className="p-1 hover:bg-gray-200 rounded"
                    title="Duplicate file"
                  >
                    <DocumentDuplicateIcon className="w-4 h-4 text-gray-500" />
                  </button>
                </>
              )}
              <button
                onClick={() => node.type === 'folder' ? onDeleteFolder(fullPath) : onDeleteFile(fullPath)}
                className="p-1 hover:bg-gray-200 rounded"
                title={`Delete ${node.type}`}
              >
                <TrashIcon className="w-4 h-4 text-red-500" />
              </button>
            </div>
          </div>
          
          {showNewFolderInput === fullPath && (
            <div className="ml-6 mt-1 flex gap-2">
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                placeholder="New folder name"
                className="px-2 py-1 border rounded text-sm flex-grow"
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleCreateFolder(fullPath);
                  if (e.key === 'Escape') {
                    setShowNewFolderInput(null);
                    setNewFolderName('');
                  }
                }}
              />
              <button
                onClick={() => handleCreateFolder(fullPath)}
                className="px-2 py-1 bg-blue-500 text-white rounded text-sm"
              >
                Create
              </button>
              <button
                onClick={() => {
                  setShowNewFolderInput(null);
                  setNewFolderName('');
                }}
                className="px-2 py-1 border rounded text-sm"
              >
                Cancel
              </button>
            </div>
          )}
          
          {node.type === 'folder' && isExpanded && node.children && (
            <div className="ml-4 border-l border-gray-200 pl-2 mt-1">
              {renderTree(node.children)}
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="file-explorer">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-medium">Files</h3>
        <button
          onClick={() => setShowNewFolderInput('')}
          className="flex items-center gap-1 px-2 py-1 text-sm bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          <PlusIcon className="w-4 h-4" />
          New Folder
        </button>
      </div>
      
      {showNewFolderInput === '' && (
        <div className="mb-4 flex gap-2">
          <input
            type="text"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            placeholder="New folder name"
            className="px-2 py-1 border rounded text-sm flex-grow"
            autoFocus
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleCreateFolder('');
              if (e.key === 'Escape') {
                setShowNewFolderInput(null);
                setNewFolderName('');
              }
            }}
          />
          <button
            onClick={() => handleCreateFolder('')}
            className="px-2 py-1 bg-blue-500 text-white rounded text-sm"
          >
            Create
          </button>
          <button
            onClick={() => {
              setShowNewFolderInput(null);
              setNewFolderName('');
            }}
            className="px-2 py-1 border rounded text-sm"
          >
            Cancel
          </button>
        </div>
      )}
      
      <div className="overflow-auto max-h-[calc(100vh-16rem)]">
        {renderTree(data)}
      </div>
    </div>
  );
};

FileExplorer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(['file', 'folder']).isRequired,
    name: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    children: PropTypes.array,
    size: PropTypes.number,
    modified: PropTypes.number,
  })).isRequired,
  onFileSelect: PropTypes.func.isRequired,
  onCreateFolder: PropTypes.func.isRequired,
  onDeleteFolder: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func.isRequired,
  onMoveFile: PropTypes.func.isRequired,
  selectedFile: PropTypes.string,
};

export default FileExplorer; 