import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Set worker source
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;

const MediaViewer = ({ file, apiUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfError, setPdfError] = useState(null);

  const isImageFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension);
  };

  const isVideoFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return ['mp4', 'avi', 'mov'].includes(extension);
  };

  const isPdfFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return extension === 'pdf';
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const previousPage = () => changePage(-1);
  const nextPage = () => changePage(1);

  if (isPdfFile(file.name)) {
    return (
      <div className="h-[70vh] flex flex-col items-center bg-gray-100 rounded overflow-hidden">
        <div className="flex-1 w-full overflow-auto flex items-center justify-center p-4">
          <Document
            file={`${apiUrl}/api/files/files/${encodeURIComponent(file.path)}`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => {
              console.error('PDF Load Error:', error);
              setPdfError(error.message);
            }}
            className="max-w-full"
            loading={
              <div className="flex items-center justify-center p-4">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
              </div>
            }
          >
            {pdfError ? (
              <div className="text-red-500 p-4">
                <p>Error loading PDF: {pdfError}</p>
                <p className="text-sm mt-2">Try refreshing the page or downloading the file directly.</p>
              </div>
            ) : (
              <Page 
                pageNumber={pageNumber} 
                className="shadow-lg bg-white"
                width={Math.min(window.innerWidth * 0.8, 800)}
                renderTextLayer={true}
                renderAnnotationLayer={true}
                loading={
                  <div className="flex items-center justify-center p-4">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                  </div>
                }
              />
            )}
          </Document>
        </div>
        {numPages > 1 && (
          <div className="w-full bg-white p-4 flex items-center justify-center gap-4">
            <button
              onClick={previousPage}
              disabled={pageNumber <= 1}
              className={`p-2 rounded ${
                pageNumber <= 1 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
            >
              <ChevronLeftIcon className="w-5 h-5" />
            </button>
            <span className="text-sm">
              Page {pageNumber} of {numPages}
            </span>
            <button
              onClick={nextPage}
              disabled={pageNumber >= numPages}
              className={`p-2 rounded ${
                pageNumber >= numPages 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-gray-700 hover:bg-gray-100'
              }`}
            >
              <ChevronRightIcon className="w-5 h-5" />
            </button>
          </div>
        )}
      </div>
    );
  }

  if (isImageFile(file.name)) {
    return (
      <div className="h-[70vh] flex items-center justify-center bg-gray-100 rounded">
        <img
          src={`${apiUrl}/api/files/files/${file.path}`}
          alt={file.name}
          className="max-h-full max-w-full object-contain"
          loading="lazy"
        />
      </div>
    );
  }

  if (isVideoFile(file.name)) {
    return (
      <div className="h-[70vh] flex items-center justify-center bg-gray-100 rounded">
        <video controls className="max-h-full max-w-full">
          <source 
            src={`${apiUrl}/api/files/files/${file.path}`}
            type={file.type || 'video/mp4'}
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  return null;
};

export default MediaViewer; 