import React from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/outline';

const ToolBar = ({ 
  fileName, 
  onFileNameChange, 
  onSave, 
  onFormatJSON, 
  selectedFile, 
  isMediaFile 
}) => {
  return (
    <div className="mb-4 flex gap-2">
      <input
        type="text"
        placeholder="File name"
        value={fileName}
        onChange={(e) => onFileNameChange(e.target.value)}
        className="px-3 py-2 border rounded flex-grow"
      />
      {selectedFile && !isMediaFile(selectedFile.name) && (
        <>
          {getFileLanguage(selectedFile.name) === 'json' && (
            <button
              onClick={onFormatJSON}
              className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 flex items-center gap-2"
              title="Format JSON"
            >
              <ArrowPathIcon className="w-5 h-5" />
              Format
            </button>
          )}
          <button
            onClick={onSave}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 flex items-center gap-2"
          >
            Save
          </button>
        </>
      )}
    </div>
  );
};

const getFileLanguage = (filename) => {
  const extension = filename.split('.').pop().toLowerCase();
  switch (extension) {
    case 'json':
      return 'json';
    default:
      return '';
  }
};

export default ToolBar; 