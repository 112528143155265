import { useState, useEffect, useRef } from 'react';
import { io } from 'socket.io-client';
import {
  MagnifyingGlassIcon,
  XMarkIcon,
  ArrowDownTrayIcon,
  TrashIcon,
  FunnelIcon,
} from '@heroicons/react/24/outline';

const HIGHLIGHT_COLORS = [
  'bg-yellow-200',  // yellow
  'bg-green-200',   // green
  'bg-blue-200',    // blue
  'bg-pink-200',    // pink
  'bg-purple-200',  // purple
  'bg-orange-200',  // orange
];

const highlightText = (text, searchTerms, isRegex) => {
  if (!searchTerms || searchTerms.length === 0) return text;
  
  const stringText = typeof text === 'object' ? JSON.stringify(text, null, 2) : String(text);

  try {
    let result = stringText;
    searchTerms.forEach((term, index) => {
      const colorClass = HIGHLIGHT_COLORS[index % HIGHLIGHT_COLORS.length];
      
      if (isRegex) {
        const regex = new RegExp(term, 'gi');
        result = result.replace(regex, match => 
          `<mark class="${colorClass}">${match}</mark>`
        );
      } else {
        const escapedTerm = term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(escapedTerm, 'gi');
        result = result.replace(regex, match => 
          `<mark class="${colorClass}">${match}</mark>`
        );
      }
    });
    return result;
  } catch (e) {
    return stringText;
  }
};

function Logs() {
  const [logs, setLogs] = useState([]);
  const [filteredLogs, setFilteredLogs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isRegex, setIsRegex] = useState(false);
  const [excludedPatterns, setExcludedPatterns] = useState([]);
  const [newPattern, setNewPattern] = useState('');
  const [error, setError] = useState(null);
  const socketRef = useRef(null);
  const [showExclusionModal, setShowExclusionModal] = useState(false);
  const [success, setSuccess] = useState(null);
  const [selectedLog, setSelectedLog] = useState(null);
  const [selectedView, setSelectedView] = useState(null); // 'request' or 'response'

  useEffect(() => {
    // Initial fetch of logs
    fetchLogs();
    fetchExclusions();

    // Setup WebSocket connection with correct port
    const wsUrl = process.env.REACT_APP_API_URL;
    const socket = io(wsUrl, {
      path: '/socket.io/',
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000
    });

    socket.on('connect', () => {
      console.log('Connected to WebSocket');
      setError(null);
    });

    socket.on('new_log', (log) => {
      console.log('Received new log:', log);
      setLogs(prevLogs => [log, ...prevLogs]);
    });

    socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
      setError('Failed to connect to WebSocket server');
    });

    socketRef.current = socket;

    return () => {
      if (socket) socket.disconnect();
    };
  }, []);

  useEffect(() => {
    filterLogs();
  }, [logs, searchTerm, isRegex]);

  const fetchLogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logs`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setLogs(data.reverse()); // Show newest first
    } catch (error) {
      console.error('Error fetching logs:', error);
      setError('Failed to fetch logs');
    }
  };

  const fetchExclusions = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logs/exclusions`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setExcludedPatterns(data);
    } catch (error) {
      console.error('Error fetching exclusions:', error);
    }
  };

  const filterLogs = () => {
    if (!searchTerm) {
      setFilteredLogs(logs);
      return;
    }

    try {
      const searchTerms = searchTerm.split(' ').filter(term => term.length > 0);
      
      const filtered = logs.filter(log => {
        const stringifyValues = (obj) => {
          if (!obj) return '';
          if (typeof obj !== 'object') return String(obj);
          return Object.values(obj).map(value => {
            if (typeof value === 'object') return stringifyValues(value);
            return String(value);
          }).join(' ');
        };

        const searchableContent = [
          new Date(log.timestamp * 1000).toLocaleString(),
          log.url,
          log.method,
          log.source_ip,
          stringifyValues(log.headers),
          stringifyValues(log.request_body),
          stringifyValues(log.response),
        ].join(' ').toLowerCase();

        return searchTerms.every(term => {
          if (isRegex) {
            try {
              const regex = new RegExp(term, 'i');
              return regex.test(searchableContent);
            } catch (e) {
              return false;
            }
          }
          return searchableContent.includes(term.toLowerCase());
        });
      });

      setFilteredLogs(filtered);
      setError(null);
    } catch (error) {
      console.error('Search error:', error);
      setError('Invalid search pattern');
      setFilteredLogs(logs);
    }
  };

  const handleClearLogs = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logs/clear`, {
        method: 'POST',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      setLogs([]);
      setFilteredLogs([]);
    } catch (error) {
      console.error('Error clearing logs:', error);
      setError('Failed to clear logs');
    }
  };

  const handleAddExclusion = async () => {
    try {
      if (!newPattern.trim()) {
        setError('Pattern cannot be empty');
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logs/exclusions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ pattern: newPattern }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add exclusion pattern');
      }

      const data = await response.json();
      await fetchExclusions();
      setNewPattern('');
      setError(null);
      setSuccess(`Exclusion pattern "${newPattern}" added and applied`);
      setTimeout(() => setSuccess(null), 3000);
    } catch (error) {
      console.error('Error adding exclusion:', error);
      setError(error.message || 'Failed to add exclusion pattern');
    }
  };

  const handleRemoveExclusion = async (pattern) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/logs/exclusions/${encodeURIComponent(pattern)}`, {
        method: 'DELETE',
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await fetchExclusions();
    } catch (error) {
      console.error('Error removing exclusion:', error);
      setError('Failed to remove exclusion pattern');
    }
  };

  const handleSaveLogs = () => {
    const logsToSave = searchTerm ? filteredLogs : logs;
    const blob = new Blob([JSON.stringify(logsToSave, null, 2)], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `http_logs_${new Date().toISOString()}.json`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div className="p-4 space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold">HTTP Logs</h2>
        <div className="flex items-center gap-2">
          <span className="text-sm text-gray-500">
            Showing {filteredLogs.length} of {logs.length} logs
          </span>
          <button
            onClick={() => setShowExclusionModal(true)}
            className="p-2 text-gray-600 hover:text-gray-900"
            title="Manage Exclusions"
          >
            <FunnelIcon className="w-5 h-5" />
          </button>
          <button
            onClick={handleSaveLogs}
            className="p-2 text-gray-600 hover:text-gray-900"
            title="Save Logs"
          >
            <ArrowDownTrayIcon className="w-5 h-5" />
          </button>
          <button
            onClick={handleClearLogs}
            className="p-2 text-red-600 hover:text-red-900"
            title="Clear Logs"
          >
            <TrashIcon className="w-5 h-5" />
          </button>
        </div>
      </div>

      {error && (
        <div className="bg-red-50 text-red-500 p-4 rounded">
          {error}
        </div>
      )}

      <div className="flex gap-2">
        <div className="relative flex-grow">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search logs..."
            className="w-full pl-10 pr-4 py-2 border rounded"
          />
          <MagnifyingGlassIcon className="absolute left-3 top-2.5 w-5 h-5 text-gray-400" />
        </div>
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={isRegex}
            onChange={(e) => setIsRegex(e.target.checked)}
            className="rounded border-gray-300"
          />
          <span className="text-sm">Regex</span>
        </label>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="w-44 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Timestamp
                </th>
                <th className="w-32 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Source IP
                </th>
                <th className="w-24 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Method
                </th>
                <th className="w-64 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  URL
                </th>
                <th className="w-96 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Request Details
                </th>
                <th className="w-96 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Response
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredLogs.map((log) => (
                <tr key={log.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span dangerouslySetInnerHTML={{
                      __html: highlightText(
                        new Date(log.timestamp * 1000).toLocaleString(),
                        searchTerm.split(' ').filter(term => term.length > 0),
                        isRegex
                      )
                    }} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <span dangerouslySetInnerHTML={{
                      __html: highlightText(
                        log.source_ip || 'N/A',
                        searchTerm.split(' ').filter(term => term.length > 0),
                        isRegex
                      )
                    }} />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                      ${log.method === 'GET' ? 'bg-green-100 text-green-800' :
                        log.method === 'POST' ? 'bg-blue-100 text-blue-800' :
                          log.method === 'DELETE' ? 'bg-red-100 text-red-800' :
                            'bg-gray-100 text-gray-800'}`}
                      dangerouslySetInnerHTML={{
                        __html: highlightText(
                          log.method,
                          searchTerm.split(' ').filter(term => term.length > 0),
                          isRegex
                        )
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: highlightText(
                          log.url,
                          searchTerm.split(' ').filter(term => term.length > 0),
                          isRegex
                        )
                      }}
                    />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <pre className="whitespace-pre-wrap max-w-md overflow-x-auto">
                      <button
                        onClick={() => {
                          setSelectedLog(log);
                          setSelectedView('request');
                        }}
                        className="text-blue-600 hover:text-blue-800 underline mb-2 block"
                      >
                        View Full Request
                      </button>
                      {log.headers && (
                        <div className="mb-2">
                          <strong>Headers:</strong>
                          <div className="pl-2 space-y-1">
                            {Object.entries(log.headers).map(([key, value]) => (
                              <div key={key} className="flex">
                                <span className="font-medium min-w-[120px] truncate"
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      key,
                                      searchTerm.split(' ').filter(term => term.length > 0),
                                      isRegex
                                    )
                                  }}
                                />:
                                <span className="ml-2 truncate" title={value}
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      value,
                                      searchTerm.split(' ').filter(term => term.length > 0),
                                      isRegex
                                    )
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {log.request_body && (
                        <div>
                          <strong>Body:</strong>
                          <div className="pl-2">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: highlightText(
                                  typeof log.request_body === 'object'
                                    ? JSON.stringify(log.request_body, null, 2)
                                    : log.request_body,
                                  searchTerm.split(' ').filter(term => term.length > 0),
                                  isRegex
                                )
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </pre>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    <pre className="whitespace-pre-wrap max-w-md overflow-x-auto">
                      <button
                        onClick={() => {
                          setSelectedLog(log);
                          setSelectedView('response');
                        }}
                        className="text-blue-600 hover:text-blue-800 underline mb-2 block"
                      >
                        View Full Response
                      </button>
                      {log.response && (
                        <>
                          <div className="mb-2">
                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                              ${log.response.status < 300 ? 'bg-green-100 text-green-800' :
                                log.response.status < 400 ? 'bg-blue-100 text-blue-800' :
                                  'bg-red-100 text-red-800'}`}>
                              Status: {log.response.status}
                            </span>
                          </div>
                          {log.response.headers && (
                            <div className="mb-2">
                              <strong>Headers:</strong>
                              <div className="pl-2 space-y-1">
                                {Object.entries(log.response.headers).map(([key, value]) => (
                                  <div key={key} className="flex">
                                    <span className="font-medium min-w-[120px] truncate"
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          key,
                                          searchTerm.split(' ').filter(term => term.length > 0),
                                          isRegex
                                        )
                                      }}
                                    />:
                                    <span className="ml-2 truncate" title={value}
                                      dangerouslySetInnerHTML={{
                                        __html: highlightText(
                                          value,
                                          searchTerm.split(' ').filter(term => term.length > 0),
                                          isRegex
                                        )
                                      }}
                                    />
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                          {log.response.body && (
                            <div>
                              <strong>Body:</strong>
                              <div className="pl-2">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: highlightText(
                                      typeof log.response.body === 'object'
                                        ? JSON.stringify(log.response.body, null, 2)
                                        : log.response.body,
                                      searchTerm.split(' ').filter(term => term.length > 0),
                                      isRegex
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </pre>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Exclusion Rules Modal */}
      {showExclusionModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-lg w-full">
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">Manage Exclusion Rules</h3>
                <button
                  onClick={() => setShowExclusionModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              <div className="space-y-4">
                <div className="flex gap-2">
                  <input
                    type="text"
                    value={newPattern}
                    onChange={(e) => setNewPattern(e.target.value)}
                    placeholder="Enter regex pattern..."
                    className="flex-grow px-3 py-2 border rounded"
                  />
                  <button
                    onClick={handleAddExclusion}
                    className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
                  >
                    Add
                  </button>
                </div>

                <div className="space-y-2">
                  {excludedPatterns.map((pattern) => (
                    <div key={pattern} className="flex justify-between items-center p-2 bg-gray-50 rounded">
                      <code className="text-sm">{pattern}</code>
                      <button
                        onClick={() => handleRemoveExclusion(pattern)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <XMarkIcon className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {selectedLog && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-hidden">
            <div className="p-6 flex flex-col h-full">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-medium">
                  {selectedView === 'request' ? 'Request Details' : 'Response Details'}
                </h3>
                <button
                  onClick={() => {
                    setSelectedLog(null);
                    setSelectedView(null);
                  }}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              
              <div className="overflow-y-auto flex-grow">
                {selectedView === 'request' ? (
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-medium mb-2">Headers</h4>
                      <pre className="bg-gray-50 p-4 rounded overflow-x-auto">
                        <span dangerouslySetInnerHTML={{
                          __html: highlightText(
                            selectedLog.headers,
                            searchTerm.split(' ').filter(term => term.length > 0),
                            isRegex
                          )
                        }} />
                      </pre>
                    </div>
                    {selectedLog.request_body && (
                      <div>
                        <h4 className="font-medium mb-2">Body</h4>
                        <pre className="bg-gray-50 p-4 rounded overflow-x-auto">
                          <span dangerouslySetInnerHTML={{
                            __html: highlightText(
                              typeof selectedLog.request_body === 'object'
                                ? JSON.stringify(selectedLog.request_body, null, 2)
                                : selectedLog.request_body,
                              searchTerm.split(' ').filter(term => term.length > 0),
                              isRegex
                            )
                          }} />
                        </pre>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="space-y-4">
                    <div className="mb-4">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium
                        ${selectedLog.response.status < 300 ? 'bg-green-100 text-green-800' :
                          selectedLog.response.status < 400 ? 'bg-blue-100 text-blue-800' :
                            'bg-red-100 text-red-800'}`}>
                        Status: {selectedLog.response.status}
                      </span>
                    </div>
                    <div>
                      <h4 className="font-medium mb-2">Headers</h4>
                      <pre className="bg-gray-50 p-4 rounded overflow-x-auto">
                        <span dangerouslySetInnerHTML={{
                          __html: highlightText(
                            selectedLog.response.headers,
                            searchTerm.split(' ').filter(term => term.length > 0),
                            isRegex
                          )
                        }} />
                      </pre>
                    </div>
                    {selectedLog.response.body && (
                      <div>
                        <h4 className="font-medium mb-2">Body</h4>
                        <pre className="bg-gray-50 p-4 rounded overflow-x-auto">
                          <span dangerouslySetInnerHTML={{
                            __html: highlightText(
                              selectedLog.response.body,
                              searchTerm.split(' ').filter(term => term.length > 0),
                              isRegex
                            )
                          }} />
                        </pre>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Logs; 