import React from 'react';
import Editor from '@monaco-editor/react';

const FileEditor = ({ file, content, onChange, onMount }) => {
  const isMediaFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'webp', 'mp3', 'mp4', 'avi', 'mov'].includes(extension);
  };

  const isImageFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension);
  };

  const isVideoFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return ['mp4', 'avi', 'mov'].includes(extension);
  };

  const isAudioFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return ['mp3'].includes(extension);
  };

  const getFileLanguage = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'js':
      case 'jsx':
        return 'javascript';
      case 'py':
        return 'python';
      case 'json':
        return 'json';
      case 'html':
        return 'html';
      case 'css':
        return 'css';
      case 'txt':
        return 'plaintext';
      default:
        return 'plaintext';
    }
  };

  const handleEditorChange = (value) => {
    console.log('Editor content changed:', value?.slice(0, 100) + '...');
    onChange(value);
  };

  // Render media content based on file type
  if (isMediaFile(file.name)) {
    if (isImageFile(file.name)) {
      return (
        <div className="h-[70vh] flex items-center justify-center bg-gray-100 rounded">
          <img
            src={`${process.env.REACT_APP_API_URL}/api/files/files/${file.path}`}
            alt={file.name}
            className="max-h-full max-w-full object-contain"
            loading="lazy"
          />
        </div>
      );
    }

    if (isVideoFile(file.name)) {
      return (
        <div className="h-[70vh] flex items-center justify-center bg-gray-100 rounded">
          <video controls className="max-h-full max-w-full">
            <source 
              src={`${process.env.REACT_APP_API_URL}/api/files/files/${file.path}`}
              type={file.type || 'video/mp4'}
            />
            Your browser does not support the video tag.
          </video>
        </div>
      );
    }

    if (isAudioFile(file.name)) {
      return (
        <div className="h-[70vh] flex items-center justify-center bg-gray-100 rounded">
          <audio controls>
            <source 
              src={`${process.env.REACT_APP_API_URL}/api/files/files/${file.path}`}
              type="audio/mpeg"
            />
            Your browser does not support the audio tag.
          </audio>
        </div>
      );
    }
  }

  // Default text editor view for non-media files
  return (
    <Editor
      height="70vh"
      language={getFileLanguage(file.name)}
      value={content}
      onChange={handleEditorChange}
      onMount={onMount}
      theme="vs-dark"
      options={{
        minimap: { enabled: false },
        fontSize: 14,
        wordWrap: 'on',
        lineNumbers: 'on',
        folding: true,
        automaticLayout: true,
        formatOnPaste: false,
        formatOnType: false,
        detectIndentation: false,
        autoIndent: 'none'
      }}
    />
  );
};

export default FileEditor; 