import React from 'react';

const ErrorDisplay = ({ error }) => {
  if (!error) return null;
  
  return (
    <div className="col-span-12 bg-red-50 text-red-500 p-4 rounded">
      {error}
    </div>
  );
};

export default ErrorDisplay; 