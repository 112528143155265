import { useState, useEffect } from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import FileExplorer from '../FileExplorer';
import MediaViewer from './MediaViewer';
import FileEditor from './FileEditor';
import ToolBar from './ToolBar';
import ErrorDisplay from './ErrorDisplay';
import { Tab } from '@headlessui/react';
import HexEditor from './HexEditor';
import MetadataEditor from './MetadataEditor';

const DEBUG = process.env.NODE_ENV === 'development';
const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';

function ContentManagement() {
  const [files, setFiles] = useState([]);
  const [fileTree, setFileTree] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editorContent, setEditorContent] = useState('');
  const [fileName, setFileName] = useState('');
  const [error, setError] = useState(null);
  const [editorInstance, setEditorInstance] = useState(null);

  useEffect(() => {
    fetchFiles();
    fetchFileTree();
  }, []);

  const debugLog = (...args) => {
    if (DEBUG) {
      console.log('[ContentManagement]', ...args);
    }
  };

  // File operations
  const fetchFiles = async () => {
    debugLog('Fetching file list');
    try {
      const url = `${API_URL}/api/files/files`;
      debugLog('Fetching from:', url);
      
      const response = await fetch(url, {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });
      debugLog('Response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        debugLog('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      debugLog('Received files:', data);
      
      setFiles(data);
    } catch (error) {
      console.error('Error fetching files:', error);
      setError(`Failed to fetch files: ${error.message}`);
    }
  };

  const fetchFileTree = async () => {
    try {
      const response = await fetch(`${API_URL}/api/files/tree`);
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      setFileTree(data);
    } catch (error) {
      console.error('Error fetching file tree:', error);
      setError('Failed to fetch file tree');
    }
  };

  const handleCreateFolder = async (path) => {
    try {
      const response = await fetch(`${API_URL}/api/files/folders`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ path }),
      });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await fetchFileTree();
    } catch (error) {
      console.error('Error creating folder:', error);
      setError('Failed to create folder');
    }
  };

  const handleDeleteFolder = async (path) => {
    if (!window.confirm(`Are you sure you want to delete this folder?`)) return;
    
    try {
      const response = await fetch(
        `${API_URL}/api/files/folders/${path}`,
        { method: 'DELETE' }
      );
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await fetchFileTree();
    } catch (error) {
      console.error('Error deleting folder:', error);
      setError('Failed to delete folder');
    }
  };

  const handleMoveFile = async (sourcePath, destPath) => {
    try {
      debugLog('Moving file:', { sourcePath, destPath });
      
      const cleanSourcePath = sourcePath.replace(/^\/+/, '');
      const cleanDestPath = destPath.replace(/^\/+/, '');
      
      const response = await fetch(`${API_URL}/api/files/move`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          source: cleanSourcePath, 
          destination: cleanDestPath 
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }
      
      await fetchFileTree();
    } catch (error) {
      console.error('Error moving file:', error);
      setError(`Failed to move file: ${error.message}`);
    }
  };

  const handleFileSelect = async (file) => {
    debugLog('Selecting file:', file);
    try {
      setSelectedFile(file);
      setFileName(file.name);
      
      if (isMediaFile(file.name)) {
        setEditorContent('');
        setError(null);
        return;
      }
      
      const filePath = file.path;
      const url = `${API_URL}/api/files/files/${filePath}`;
      debugLog('Fetching file from:', url);
      
      const response = await fetch(url);
      debugLog('Response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        debugLog('Error response:', errorText);
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorText}`);
      }
      
      // Get the raw text content
      const rawText = await response.text();
      debugLog('Received raw response:', rawText);
      
      // Only parse JSON for .json files
      const extension = file.name.split('.').pop().toLowerCase();
      if (extension === 'json') {
        try {
          const parsedJson = JSON.parse(rawText);
          setEditorContent(JSON.stringify(parsedJson, null, 2));
        } catch (e) {
          debugLog('Failed to parse JSON content:', e);
          setEditorContent(rawText);
        }
      } else {
        // For all other files, show the raw content without any parsing
        setEditorContent(rawText);
      }
      
      setError(null);
    } catch (error) {
      console.error('Error loading file:', error);
      setError(`Failed to load file: ${error.message}`);
    }
  };

  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      
      debugLog('Uploading file to root:', file.name);

      const response = await fetch(`${API_URL}/api/files/upload`, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      await fetchFiles();
      
      const uploadedFile = { 
        name: file.name,
        path: file.name,
        type: 'file'
      };
      
      await handleFileSelect(uploadedFile);
    } catch (error) {
      console.error('Error uploading file:', error);
      setError('Failed to upload file');
    }
  };

  const handleSaveFile = async () => {
    if (!fileName) {
      setError('Please enter a file name');
      return;
    }

    try {
      const filePath = selectedFile?.path?.includes('/') 
        ? selectedFile.path 
        : fileName;
      
      debugLog('Saving file:', {
        fileName,
        filePath,
        contentLength: editorContent?.length,
        contentPreview: editorContent?.slice(0, 100)
      });

      // Get the file extension
      const extension = fileName.split('.').pop().toLowerCase();
      let contentToSave = editorContent || '';

      // Only handle JSON files specially
      if (extension === 'json') {
        try {
          const parsedJson = JSON.parse(contentToSave);
          contentToSave = JSON.stringify(parsedJson, null, 2);
        } catch (jsonError) {
          setError('Invalid JSON: ' + jsonError.message);
          return;
        }
      } else if (extension === 'txt') {
        // For txt files, ensure we're saving the raw content
        contentToSave = editorContent;
      }

      const response = await fetch(`${API_URL}/api/files/save-text`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filename: filePath,
          content: contentToSave
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      debugLog('File saved successfully');
      await fetchFileTree();
      setError(null);
    } catch (error) {
      console.error('Error saving file:', error);
      setError(`Failed to save file: ${error.message}`);
    }
  };

  const handleDeleteFile = async (filePath) => {
    if (!window.confirm('Are you sure you want to delete this file?')) {
      return;
    }

    try {
      debugLog('Deleting file:', filePath);
      
      const response = await fetch(
        `${API_URL}/api/files/files/${encodeURIComponent(filePath)}`,
        { method: 'DELETE' }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      await fetchFileTree();
      
      if (selectedFile?.path === filePath) {
        setSelectedFile(null);
        setEditorContent('');
        setFileName('');
      }
    } catch (error) {
      console.error('Error deleting file:', error);
      setError(`Failed to delete file: ${error.message}`);
    }
  };

  const handleFormatJSON = () => {
    try {
      const formatted = JSON.stringify(JSON.parse(editorContent), null, 2);
      setEditorContent(formatted);
      setError(null);
    } catch (error) {
      setError('Invalid JSON: ' + error.message);
    }
  };

  const handleCreateNewFile = () => {
    const defaultFileName = 'new_file.txt';
    
    debugLog('Creating new file in root:', defaultFileName);
    
    setSelectedFile({ 
      name: defaultFileName,
      path: defaultFileName,
      type: 'file'
    });
    setFileName(defaultFileName);
    setEditorContent('');
    setError(null);
  };

  const isMediaFile = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    return ['jpg', 'jpeg', 'png', 'gif', 'webp', 'mp3', 'mp4', 'avi', 'mov', 'pdf'].includes(extension);
  };

  const renderContent = () => {
    if (!selectedFile) {
      return (
        <div className="h-[70vh] flex items-center justify-center text-gray-500">
          Select a file to view or edit
        </div>
      );
    }

    return (
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1 mb-4">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-sm font-medium leading-5
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            Editor
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-sm font-medium leading-5
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            Hex Editor
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-sm font-medium leading-5
              ${selected
                ? 'bg-white text-blue-700 shadow'
                : 'text-gray-600 hover:bg-white/[0.12] hover:text-blue-600'
              }`
            }
          >
            Metadata
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel>
            {isMediaFile(selectedFile.name) ? (
              <MediaViewer 
                file={selectedFile} 
                apiUrl={API_URL} 
              />
            ) : (
              <FileEditor
                file={selectedFile}
                content={editorContent}
                onChange={setEditorContent}
                onMount={setEditorInstance}
              />
            )}
          </Tab.Panel>
          <Tab.Panel>
            <HexEditor
              content={editorContent}
              onChange={setEditorContent}
              file={selectedFile}
              apiUrl={API_URL}
            />
          </Tab.Panel>
          <Tab.Panel>
            <MetadataEditor
              file={selectedFile}
              apiUrl={API_URL}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    );
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <ErrorDisplay error={error} />
      
      <div className="col-span-3 bg-gray-50 p-4 rounded-lg">
        <div className="flex flex-col gap-4">
          <button
            onClick={handleCreateNewFile}
            className="w-full py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
          >
            <PlusIcon className="w-5 h-5" />
            Create New File
          </button>
          
          <input
            type="file"
            onChange={handleFileUpload}
            className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
          />
          
          <FileExplorer
            data={fileTree}
            onFileSelect={handleFileSelect}
            onCreateFolder={handleCreateFolder}
            onDeleteFolder={handleDeleteFolder}
            onDeleteFile={handleDeleteFile}
            onMoveFile={handleMoveFile}
            selectedFile={selectedFile?.path}
          />
        </div>
      </div>

      <div className="col-span-9">
        <ToolBar
          fileName={fileName}
          onFileNameChange={setFileName}
          onSave={handleSaveFile}
          onFormatJSON={handleFormatJSON}
          selectedFile={selectedFile}
          isMediaFile={isMediaFile}
        />
        {renderContent()}
      </div>
    </div>
  );
}

export default ContentManagement; 