import React, { useState, useEffect } from 'react';
import { XCircleIcon } from '@heroicons/react/24/outline';

const HexEditor = ({ content, file, apiUrl }) => {
  const [hexData, setHexData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const bytesPerRow = 16;

  useEffect(() => {
    const loadBinaryData = async () => {
      try {
        setLoading(true);
        setError(null);

        const response = await fetch(`${apiUrl}/api/files/files/${encodeURIComponent(file.path)}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        
        const blob = await response.blob();
        const arrayBuffer = await blob.arrayBuffer();
        const bytes = new Uint8Array(arrayBuffer);
        setHexData(Array.from(bytes));
      } catch (error) {
        console.error('Error loading binary data:', error);
        setError('Failed to load file content');
      } finally {
        setLoading(false);
      }
    };

    if (file) {
      loadBinaryData();
    }
  }, [file, apiUrl]);

  const renderHexView = () => {
    const rows = [];
    for (let i = 0; i < hexData.length; i += bytesPerRow) {
      const bytes = hexData.slice(i, i + bytesPerRow);
      const hexValues = bytes.map(byte => byte.toString(16).padStart(2, '0'));
      const asciiValues = bytes.map(byte => {
        const char = String.fromCharCode(byte);
        return byte >= 32 && byte <= 126 ? char : '.';
      });

      rows.push(
        <div key={i} className="flex space-x-4 font-mono text-sm">
          <div className="w-16 text-gray-500">
            {i.toString(16).padStart(8, '0')}
          </div>
          <div className="w-96 flex flex-wrap">
            {hexValues.map((hex, index) => (
              <div key={index} className="w-6">
                {hex}
              </div>
            ))}
          </div>
          <div className="w-32 text-gray-600">
            {asciiValues.join('')}
          </div>
        </div>
      );
    }
    return rows;
  };

  if (loading) {
    return (
      <div className="h-[70vh] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-[70vh] flex items-center justify-center text-red-500">
        <div className="flex items-center gap-2">
          <XCircleIcon className="w-5 h-5" />
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="h-[70vh] overflow-auto bg-white rounded p-4">
      <div className="font-mono text-sm">
        <div className="flex space-x-4 text-gray-700 font-semibold mb-2">
          <div className="w-16">Offset</div>
          <div className="w-96">Hexadecimal</div>
          <div className="w-32">ASCII</div>
        </div>
        {renderHexView()}
      </div>
    </div>
  );
};

export default HexEditor;